'use client';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import Script from 'next/script';
import { useTheme } from 'next-themes';

import { ReactNode } from 'react';

import classNames from 'classnames';

import { Footer } from '@shared/common/components/Footer';
import { NavLayout } from '@shared/common/components/NavLayout';
import {
  storePrimaryNav,
  storeQuickNavItems,
} from '@shared/common/components/NavLayout/navSetting';
import PageHeader, {
  PageHeaderBreadcrumbsItem,
  PageHeaderProps,
} from '@shared/common/components/PageHeader';
import { useAuth } from '@shared/common/providers/AuthProvider';
import {
  useAuthModal,
  useBanxaModal,
  useSupportModal,
} from '@shared/common/providers/GlobalModalsProvider';
import { getProcessEnvGlobalConfig } from '@shared/config/global';
import { captchaContainerName } from '@shared/helpers/captcha';

import { MagicsquareLogo } from '@ui-uikit/lib/components/logos/MagicsquareLogo';

import { useGetBanner } from './hooks';

const NotificationBannerPortal = dynamic(
  () =>
    import('@store:web/components/providers/NotificationBannerProvider/NotificationBannerPortal'),
  { ssr: false },
);

const ClientSideHooks = dynamic(() => import('../ClientSideHooks/ClientSideHooks'), { ssr: false });

interface MainLayoutProps {
  children?: ReactNode;
}

export const NotificationBanner = () => {
  const pathname = usePathname();
  const { banner, className } = useGetBanner();

  const isHidden = ['/app/', '/developer/'].some((path) => pathname.startsWith(path));

  if (isHidden || !banner) {
    return null;
  }

  return (
    <NotificationBannerPortal>
      <div className={classNames('notification-banner', className)}>
        <div dangerouslySetInnerHTML={{ __html: banner }} />
      </div>
    </NotificationBannerPortal>
  );
};

const discoverBreadcrumbItem: PageHeaderBreadcrumbsItem = {
  id: 'discover',
  label: 'Discover',
  href: '/',
};

const membershipPageHeaderConfig: Pick<PageHeaderProps, 'title' | 'breadcrumbs'> = {
  title: 'Magic Membership',
  breadcrumbs: [
    discoverBreadcrumbItem,
    {
      id: 'membership',
      label: 'Magic Membership',
    },
  ],
};

const myApplicationsPageHeaderConfig: Pick<PageHeaderProps, 'title' | 'breadcrumbs'> = {
  title: 'My Applications',
  breadcrumbs: [
    {
      id: 'discover',
      label: 'Discover',
      href: '/',
    },
    {
      id: 'my-applications',
      label: 'My Applicaitons',
    },
  ],
};

const MagicRewardsPageHeaderConfig: Pick<PageHeaderProps, 'title' | 'breadcrumbs'> = {
  title: 'Magic Rewards',
  breadcrumbs: [
    {
      id: 'discover',
      label: 'Discover',
      href: '/',
    },
    {
      id: 'magic-rewards',
      label: 'Magic Rewards',
    },
  ],
};

const developerSettingsPageHeaderConfig: Pick<PageHeaderProps, 'title' | 'breadcrumbs'> = {
  title: 'Developer Profile',
  breadcrumbs: [
    {
      id: 'discover',
      label: 'Discover',
      href: '/',
    },
    {
      id: 'developer-profile',
      label: 'Developer Profile',
      href: '/profile/developer-info',
    },
  ],
};

const referAFriendPageHeaderConfig: Pick<PageHeaderProps, 'title' | 'breadcrumbs'> = {
  title: 'Invite Friends and earn 250+ Karma per referral',
  breadcrumbs: [
    discoverBreadcrumbItem,
    {
      id: 'raf',
      label: 'Invite Friends',
    },
  ],
};

const magicIdPageHeaderConfig: Pick<PageHeaderProps, 'title' | 'breadcrumbs'> = {
  title: 'Magic ID',
  breadcrumbs: [
    discoverBreadcrumbItem,
    {
      id: 'magic-id',
      label: 'Magic ID',
    },
  ],
};

const scoreWidgetPageHeaderConfig: Pick<PageHeaderProps, 'title' | 'breadcrumbs'> = {
  title: 'Score Widget',
  breadcrumbs: [
    discoverBreadcrumbItem,
    {
      id: 'score-widget',
      label: 'Score Widget',
    },
  ],
};

const campaignReviewPageHeaderConfig: Pick<PageHeaderProps, 'title' | 'breadcrumbs'> = {
  title: 'Campaign Review',
  breadcrumbs: [
    discoverBreadcrumbItem,
    {
      id: 'campaign-review',
      label: 'Campaign Review',
    },
  ],
};

// TODO: make portal based logic so we can configure it from the page
const pageHeaderConfig: Record<string, Pick<PageHeaderProps, 'title' | 'breadcrumbs'>> = {
  '/profile/membership': membershipPageHeaderConfig,
  '/profile/membership/dashboard': membershipPageHeaderConfig,
  '/profile/membership/plans': membershipPageHeaderConfig,
  '/profile/refer-a-friend/friends': referAFriendPageHeaderConfig,
  '/profile/refer-a-friend/how-it-works': referAFriendPageHeaderConfig,
  '/profile/refer-a-friend/partner-earnings': referAFriendPageHeaderConfig,
  '/profile/developer-dapps': myApplicationsPageHeaderConfig,
  '/profile/edit-developer-profile': developerSettingsPageHeaderConfig,
  '/profile/magic-id': magicIdPageHeaderConfig,
  '/profile/magic-id/wallets': magicIdPageHeaderConfig,
  '/profile/magic-id/socials': magicIdPageHeaderConfig,
  '/profile/magic-id/human-verification': magicIdPageHeaderConfig,
  '/profile/magic-id/kyc-verification': magicIdPageHeaderConfig,
  '/profile/karma': MagicRewardsPageHeaderConfig,
  '/profile/developer-info/widget': scoreWidgetPageHeaderConfig,
  '/profile/developer-campaign': campaignReviewPageHeaderConfig,
};

const MainLayout = ({ children }: MainLayoutProps) => {
  const { user, isLoading, logout } = useAuth();
  const pathname = usePathname();
  const isDev = getProcessEnvGlobalConfig('isDev');
  const theme = useTheme();
  const showFooter = !['/magic-boost', '/magic-swap', '/spaces'].some((path) =>
    pathname.startsWith(path),
  );

  const showPageGradient = !['/spaces'].some((path) => pathname.startsWith(path));
  const modal = useBanxaModal();
  const { userEvmWallet } = useAuth();

  const authModal = useAuthModal();
  const supportModal = useSupportModal();

  const pageHeader = pageHeaderConfig[pathname];

  const handleConnectClick = () => {
    authModal.open();
  };

  const handleBanxaBuyToken = () => {
    modal.open({
      walletAddress: userEvmWallet?.attributes.address,
    });
  };

  return (
    <>
      <div id={captchaContainerName} className="hidden" />

      <NotificationBanner />

      {/* <Sidebar className="max-lg:hidden" /> */}

      <NavLayout
        showThemeToggle={isDev}
        showMobileFeatureNav
        primaryNavMenuTitle="Magic Store"
        Logo={MagicsquareLogo}
        theme={theme.theme}
        onThemeChange={theme.setTheme}
        isLoading={isLoading}
        user={user ? { data: user } : undefined}
        onLogin={handleConnectClick}
        onLogout={logout}
        onSupport={supportModal.open}
        onBanxaBuyToken={handleBanxaBuyToken}
        primaryNavItems={storePrimaryNav}
        quickNavItems={storeQuickNavItems}
      >
        <div className={classNames('flex-1 relative flex flex-col')}>
          {showPageGradient && (
            <div className="h-[14rem] bg-page-gradient absolute top-0 left-0 w-full" />
          )}

          <div className="relative flex flex-1 flex-col">
            {pageHeader && <PageHeader className="v2-container" {...pageHeader} />}

            {children}

            {showFooter && <Footer />}
          </div>
        </div>
      </NavLayout>

      <ClientSideHooks />
      {/* NOTE: not sure why we need this script */}
      <Script src="https://accounts.google.com/gsi/client" async defer strategy="lazyOnload" />
    </>
  );
};

export default MainLayout;
